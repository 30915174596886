<template>
    <div class="slider">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../assets/slide1.png" class="d-block w-100 dark-filter" alt="Slide 1">
                </div>
                <div class="carousel-item">
                    <img src="../assets/slide2.png" class="d-block w-100 dark-filter" alt="Slide 2">
                </div>
                <div class="carousel-item">
                    <img src="../assets/slide3.png" class="d-block w-100 dark-filter" alt="Slide 3">
                </div>
            </div>
        </div>
        <div class="logo-overlay">
            <img src="../assets/logomscic.png" alt="Logo Gereja">
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>VISI</h2>
                <p>Menjadi Gereja yang berkatakter Kristus dan berjiwa misioner</p>
            </div>
            <div class="col-md-6">
                <h2>MISI</h2>
                <p>Nama Yesus Ditinggikan</p>
                <p>Banyak Jiwa Diselamatkan</p>
                <p>KerajaanNya Ditegakkan</p>
            </div>
        </div>
    </div>
    <div class="parallax text-center mt-50">
        <h1 class="praise-text">
            <span class="word">Praise</span>
            <span class="word">The</span>
            <span class="word">Lord</span>
        </h1>
    </div>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h2 class="textev">Our Events</h2>
        </div>
        <div class="poster-container">
            <button class="scroll-button prev" @click="scrollLeft">&#10094;</button>
            <div class="poster-slider" ref="posterSlider">
                <div 
                    v-for="(event, index) in events" 
                    :key="index" 
                    class="poster-card" 
                    @click="openModal(event.image)"
                >
                    <img :src="event.image" alt="Event Poster" />
                </div>
            </div>
            <button class="scroll-button next" @click="scrollRight">&#10095;</button>
        </div>
        <div class="modal" v-if="isModalOpen" @click="closeModal">
            <div class="modal-content" @click.stop>
                <span class="close" @click="closeModal">&times;</span>
                <img :src="modalImage" alt="Enlarged Poster" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="photo-gallery">
                <img v-for="(photo, index) in photos" :key="index" :src="photo" alt="Gallery Photo" class="gallery-image" />
            </div>
        </div>
        <div class="col-md-6">
            <h3 class="textkom">KOMSEL GABUNGAN</h3>
            <p class="description">Komsel Gabungan adalah kegiatan persekutuan kecil dalam gereja yang diadakan secara berkala, biasanya melibatkan beberapa kelompok komsel yang bergabung dalam satu acara. Kegiatan ini bertujuan untuk mempererat tali persaudaraan antar jemaat, berbagi pengalaman iman, serta mendalami firman Tuhan secara bersama-sama. Dalam suasana yang akrab, anggota komsel dapat saling mendukung, berdoa, dan saling menguatkan dalam iman, sehingga memperkuat komunitas gereja.</p>
            <p class="description">Mari kita satukan hati dan jiwa dalam kasih Kristus, belajar bersama, dan saling menguatkan.</p>
            <a href="mailto:gbimscic@gmail.com?subject=Komsel%20Gabungan&body=Shalom,%0D%0A%0D%0ASaya ingin bergabung dalam Komsel Gabungan MSCIC. Berikut ini adalah data saya:%0D%0A%0D%0ANama:%0D%0AAlamat:%0D%0A%0D%0ASaya mohon informasi mengenai cara bergabung dalam komsel tersebut. Terima kasih atas perhatian Anda.%0D%0A%0D%0Aterima kasih." target="_blank" class="btn komsel-button">Komsel Gabungan</a>
        </div>
    </div>
    <div class="service-team-section">
        <div class="row">
            <div class="col-md-4">
                <h3 class="team-title">Tim Pelayanan</h3>
            </div>
            <div class="col-md-4 text-center carousel-container">
                <img :src="photo[currentPhotoIndex]" alt="Tim Pelayanan" class="team-image" />
            </div>
            <div class="col-md-4 text-center form-background">
                <p class="ayat-text">Kolose 3:23-24</p>
                <p class="description-text">"Apa pun juga yang kamu perbuat, perbuatlah dengan segenap hatimu seperti untuk Tuhan dan bukan untuk manusia, karena kamu tahu, bahwa dari Tuhanlah kamu akan menerima sebagai upah warisan. Kamu adalah hamba-hamba Kristus."</p>
                <router-link to="/contact" class="contact-button">Form Pelayanan</router-link>
            </div>
        </div>
    </div>
    <div class="highlights-section">
        <div class="overlay-parallax-hl"></div>
        <div class="left-column">
            <h2 class="highlights-title">Highlights</h2>
        </div>
        <div class="right-column">
            <div class="image-container" ref="imageContainer">
                <img v-for="(photo, index) in highlightPhotos" :key="index" :src="photo" class="highlight-image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyHome',
    data() {
        return {
            events: [
                { image: require("@/assets/event1.png") },
                { image: require("@/assets/event2.png") },
                { image: require("@/assets/event9.png") },
                { image: require("@/assets/event4.png") },
                { image: require("@/assets/event8.png") },
                { image: require("@/assets/event6.png") },
                { image: require("@/assets/event3.png") },
                { image: require("@/assets/event7.png") },
                { image: require("@/assets/event10.png") },
                { image: require("@/assets/event11.png") },
            ],
            photos: [ 
                require("@/assets/komsel1.jpg"),
                require("@/assets/komsel8.jpg"),
                require("@/assets/komsel3.jpg"),
                require("@/assets/komsel4.jpg"),
                require("@/assets/komsel5.jpg"),
                require("@/assets/komsel6.jpg"),
                require("@/assets/komsel7.jpg"),
                require("@/assets/komsel2.jpg"),
                require("@/assets/komsel9.jpg"),
            ],
            photo: [ 
                require("@/assets/tamborine.png"), 
                require("@/assets/band.png"), 
                require("@/assets/usher.png"), 
                require("@/assets/choir.png"), 
                require("@/assets/sound.png"), 
                require("@/assets/dance.png"), 
                require("@/assets/mulmed.png"), 
                require("@/assets/singer.png"), 
            ],
            highlightPhotos: [
                require("@/assets/h1.png"),
                require("@/assets/h2.png"),
                require("@/assets/h3.png"),
                require("@/assets/h4.png"),
                require("@/assets/h5.png"),
                require("@/assets/h6.png"),
            ], 
            currentPhotoIndex: 0, 
            intervalId: null,
            isModalOpen: false, 
            modalImage: '' 
        };
    },
    mounted() { 
        this.startCarousel(); 
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        scrollLeft() {
            this.$refs.posterSlider.scrollBy({
                left: -220,
                behavior: "smooth",
            });
        },
        scrollRight() {
            this.$refs.posterSlider.scrollBy({
                left: 220,
                behavior: "smooth",
            });
        },
        openModal(image) {
            this.modalImage = image; 
            this.isModalOpen = true; 
        },
        closeModal() {
            this.isModalOpen = false; 
        },
        startCarousel() { 
            this.intervalId = setInterval(() => { 
                this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.photo.length;
            }, 3000); 
        },
    },
};
</script>

<style scoped>
.slider { 
    position: relative;
}

.dark-filter { 
    filter: brightness(35%);
}

.logo-overlay { 
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    animation: float 3s ease-in-out infinite;
}

.logo-overlay img { 
    width: 1000px;
    height: auto;
}

.container { 
    margin-top: 80px;
    margin-bottom: 80px;
}

.container h2 { 
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    text-align: center;
    color: darkred;
    margin-bottom: 20px;
}

.container p { 
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: grey;
    margin: 5px 0;
}

.parallax {
    background-image: url(@/assets/homep.png);
    height: 450px; 
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: relative;
    color: white; 
}

.praise-text { 
    font-family: Brush Script MT;
    font-size: 208px;
    color: white;
    display: flex;
    justify-content: center;
    gap: 10px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.word { 
    opacity: 0;
    animation: fadeInLeft 1s ease forwards;
}

.word:nth-child(1) {
    animation-delay: 0.2s;
}

.word:nth-child(2) { 
    animation-delay: 0.6s;
}

.word:nth-child(3) { 
    animation-delay: 1s;
}

.textev { 
    font-size: 40px;
    text-align: center;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif; 
    font-weight: 200;
    color: darkred;
    flex-grow: 1; 
}

.d-flex { 
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.poster-container { 
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 0 150px
}

.poster-slider { 
    display: flex;
    gap: 16px;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.poster-slider::-webkit-scrollbar { 
    display: none;
}

.poster-card { 
    width: 300px;
    flex: 0 0 auto;
}

.poster-card img { 
    width: 100%;
    margin-bottom: 8px;
}

.poster-card h5 { 
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
    font-size: 20px;
}

.scroll-button { 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 2;
    font-size: 1.5rem;
}

.scroll-button.prev { 
    left: 0;
}

.scroll-button.next { 
    right: 0;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.row { 
    display: flex;
    margin: 0;
    margin-top: 100px;
    padding: 0 15px
}

.col-md-6 { 
    flex: 1 1 40%;
}

.photo-gallery { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-width: 100%;
    overflow: hidden;
}

.gallery-image { 
    width: 100%;
    height: auto;
    object-fit: cover;
}

.textkom { 
    letter-spacing: 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    text-align: center;
}

.description { 
    text-align: justify; 
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.komsel-button {
    margin-left: 50px;
    background-color: red; 
    color: white; 
    border: none; 
    border-radius: 80px; 
    padding: 10px 20px; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 16px; 
    transition: background-color 0.3s ease; 
}

.komsel-button:hover {
    background-color: darkred;
    color: white;
}

.service-team-section { 
    margin-top: -10px;
    padding: 0 15px;
}

.row { 
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
}

.col-md-4 { 
    flex: 1 1 33.33%;
    padding: 10px;
}

.team-title { 
    margin-top: 120px;
    font-size: 44px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: darkred;
}

.team-image { 
    max-width: 100%;
    height: auto;
}

.ayat-text { 
    font-style: italic;
    font-family: cursive;
    font-size: 16px;
    color: white;
    margin-top: 45px !important;
}

.description-text { 
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.form-background { 
    background-color: darkred;
}

.contact-button { 
    display: inline-block;
    margin-top: 5px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 80px;
    transition: background-color 0.3s;
}

.contact-button:hover { 
    background-color: red;
}

.highlights-section {
    margin-bottom: 50px;
    margin-top: 80px;
    display: flex;
    height: 600px; 
    background-image: url('../assets/homepar.png');
    position: relative;
    background-attachment: fixed;
    background-size: cover;
}

.left-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.right-column {
    flex: 2; 
    overflow: hidden; 
    position: relative;
}

.image-container {
    display: flex;
    animation: slide 15s linear infinite;
}

.highlight-image {
    margin-top: 25px !important;
    width: 550px; 
    height: 550px; 
    margin: 0 4px; 
}

.highlights-title { 
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.overlay-parallax-hl { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1;
}

@keyframes fadeInLeft { 
    0% { 
        opacity: 0;
        transform: translateX(-50%);
    }
    100% { 
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes float { 
    0%, 100% { 
        transform: translate(-50%, -50%);
    }
    50% { 
        transform: translate(-50%, -55%);
    }
}

@media (max-width: 768px) { 
    .slider { 
        margin-top: 20%;
    }

    .dark-filter { 
        width: 125%!important;
        height: 95vh!important;
        object-fit: cover;
    }
    
    .logo-overlay { 
        top: 50%;
    }

    .logo-overlay img { 
        width: 350px;
        height: auto;
    }

    .dark-filter {
        height: 40%;
    }

    .container { 
        margin-top: 50px;
    }

    .container h2 {
        text-align: left;
        font-size: 4rem;
        margin-bottom: 5px;
    }

    .container p { 
        text-align: left;
        font-size: 25px;
        margin-bottom: 10px;
    }

    .textev { 
        margin-top: 10px;
    }

    .poster-container { 
        padding: 0 5px;
    }
    .poster-card { 
        width: 200px;
        padding: 5px;
    }

    .poster-card img { 
        width: 200px;
    }

    .modal-content img {
        width: 100%;
        height: auto; 
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8); 
        z-index: 1000; 
    }

    .modal-content {
        position: relative;
        max-width: 90%;
        max-height: 90%;
    }

    .photo-gallery { 
        margin-top: -10px;
    }

    .textkom { 
        margin-top: 30px;
    }

    .row { 
        flex-direction: column;
    }

    .col-md-4 { 
        flex: 1 1 100%;
        padding: 10px 0;
    }

    .team-title, .description-text { 
        margin-top: 0 !important;
    }
    .highlights-section {
        flex-direction: column; 
        height: auto; 
    }

    .left-column {
        flex: none; 
        margin-bottom: 20px; 
        text-align: center; 
    }

    .right-column {
        flex: none; 
    }

    .image-container {
        flex-direction: row; 
        justify-content: center; 
    }

    .highlight-image {
        width: 350px; 
        height: 350px; 
        margin: 10px; 
        margin-bottom: 70px;
    }

    .highlights-title {
        margin-top: 50px;
        margin-bottom: -50px;
        font-size: 44px; 
    }

    .praise-text { 
        font-size: 70px;
    }

    .description { 
        margin-left: 0;
        margin-right: 0;
    }

    .komsel-button { 
        margin-left: 0;
    }

    .ayat-text { 
        margin-top: 20px !important;
    }

    .description-text { 
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 10px !important;
    }

    .contact-button { 
        margin-bottom: 30px;
    }

    .highlights-title { 
        margin-bottom: 0;
    }

    .highlight-image { 
        margin-bottom: 40px;
    }
}
</style>